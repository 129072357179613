<template>
  <div class="mine" id="mine">
    <div>
      <div class="user-info">
        <div class="user">
          {{ info.alias }}
        </div>
        <div class="role">
          {{ info.phone | phoneFilter }}
        </div>
        <div class="company">
          {{ info.orgName }}
        </div>
        <div class="h-flex" style="margin-top: 10px;margin-bottom: 10px;padding: 0px 5px;">
          <div class="back h-flex-1 h-text-center h-flex" style="justify-content: center;">
            <van-button round size="small" type="primary" @click="signout">切换其他账号</van-button>
          </div>
        </div>
      </div>
      <div v-if="info.busiType === 'B'" class="company order" style="margin-top: 45px;text-align: center;">
        <div style="justify-content: center;display: flex;">
          <van-button round block type="primary" style="width: 193px;" @click="$router.replace({ path: '/product/order/list' })">订单管理</van-button>
        </div>
      </div>
      <div class="phone" style="position: absolute;width: 100%;bottom: 80px;">
        <div style="width: 100%; display: inline-block; vertical-align: middle;">
          <p style="margin: 0; padding: 0; font-size: 0.32rem;text-align: center;">
            <span
              >客服热线<a href="tel:4000623400"> <van-icon name="phone-o" size="15" color="#57ccc2" style="margin-left: 5px;margin-right: 5px;" /><span style="color: #57ccc2;">400-0623-400</span></a></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { request } from "@/api/service";
import store from "@/store";
import { Dialog } from "vant";
export default {
  data() {
    return {
      info: store.getters.info || {}
    };
  },
  filters: {
    phoneFilter: phone => {
      return phone ? phone.substring(0, 3) + "****" + phone.substring(7) : "";
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    mine.style.height = document.documentElement.clientHeight - 60 + "px";
  },
  methods: {
    signout() {
      Dialog.confirm({
        title: "提示",
        message: "确定要退出当前账号吗？"
      })
        .then(() => {
          request({
            url: "/afis-auth/auth/app/offline",
            method: "get"
          }).then(res => {
            let thirdId = store.getters.info.thirdId;
            store.dispatch("user/set", null);
            this.$router.push({
              path: "/login",
              query: {
                thirdId: thirdId
              }
            });
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.order:after {
  border-bottom: 1px solid #ebedf0;
  left: 16px;
}
.mine {
  background-size: 101% 101%;
  background-repeat: no-repeat;
  .user-info {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    background: #57ccc2;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    border-radius: 0% 0% 50% 50%;
    .user {
      width: 80%;
      padding-bottom: 2.1%;
      margin: 0 auto;
      font-size: 0.42rem;
      color: rgb(30, 30, 30);
    }
    .role {
      width: 80%;
      padding-bottom: 2.1%;
      margin: 0 auto;
      margin-top: 2%;
      font-size: 0.32rem;
      color: #5e5e5e;
    }
    .company {
      width: 80%;
      padding-bottom: 2.1%;
      font-size: 0.32rem;
      margin: 0 auto;
      color: #5e5e5e;
      margin-top: 2%;
    }
    .phone {
      width: 80%;
      border-bottom: 1px solid #fff;
      padding-bottom: 2.1%;
      margin: 0 auto;
      margin-top: 2%;
    }
    .signout {
      position: absolute;
      bottom: 80px;
      width: 100%;
      .btn {
        width: 6.75rem;
        height: 40px;
        margin: 0 auto;
        border-color: #fff !important;
      }
    }
  }
}
</style>
